import React from "react"

import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from '@bit/azheng.joshua-tree.button';

const Coronavirus = ({ data, pageContext, location }) => {

    let language = "en"
  
  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
      <SEO title="COVID-19 Update | Oral Surgery & Dental Implant Specialists South Carolina"
      description="Please review our latest update regarding the COVID-19 outbreak."
      pathname={location.pathname} 
      schemaPage={null}
      />
        <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          />

<div className="joshua-tree-content">
            <div className="columns">
              <div className="column">
                <div className="columns top-section white-back">
                  <div className="column"></div>
                  <div className="column is-14">
                    <div>
                      <h1 style={{marginTop: "60px"}}>Our Commitment to Your Health and Safety</h1>
                      <p>Like many of our patients, we have been monitoring the recent news and spread of the novel coronavirus (COVID-19). As members of the healthcare community, we prioritize the health and safety of our patients and staff. We strictly adhere to and exceed standards for infection control: we wear personal protective gear, use hospital-grade disinfectants, practice up-to-date sterilization protocols, and utilize single-use disposable materials. All of these measures facilitate the safety of our patients, our staff, and our practice. </p>
 
<p>Coronavirus is a virus that presents with cold and flu-like symptoms of varying severity. To ensure a healthy recovery, and to keep our staff and other patients safe, we ask that you reschedule your appointment if you or a member of your household have a cough, fever, and/or flu-like symptoms. Additionally, please call our office to reschedule if you have had any travel to an area at high risk for contracting the disease within the past 14 days.</p>
  
<p>It is a team effort to ensure the health and safety of our patients, staff, friends, and loved ones, and we thank you for your understanding and cooperation. If you have any questions or concerns, please contact our office, and one of our staff members will be more than happy to assist you.</p>
                    </div>
                    
                  </div>
                  <div className="column"></div>
                </div>
              </div>
            </div>
          </div>



      </Layout>
    </SharedStateProvider>
  )
}

export default Coronavirus